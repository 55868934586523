import React, { useContext, useEffect, useState } from "react";
import { DataContext } from "../../../Context/DataProvider";
import contentLoader from "../../../Images/loader.gif";

const InjuryHistory = ({ tmId }) => {
    const { base_url, token } = useContext(DataContext);
    const [injuryHistory, setInjuryHistory] = useState(null);
    const [contentLoading, setContentLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 10;

    useEffect(() => {
        console.log(tmId);
        fetch(`${base_url}/api/v1/player/injuries`, {
            method: "post",
            body: JSON.stringify({
                tm_id: tmId,
            }),
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.json())
            .then((data) => {
                console.log(data);
                if (data?.length) {
                    setInjuryHistory(data);
                    setContentLoading(false);
                }
            })
            .catch((error) => {
                if (error) {
                    alert(error);
                }
            });
    }, [tmId, token, base_url]);

    const totalPages = injuryHistory
        ? Math.ceil(injuryHistory.length / pageSize)
        : 0;

    // Function to handle page change
    const handlePageChange = (direction) => {
        if (direction === "next" && currentPage < totalPages) {
            setCurrentPage((prevPage) => prevPage + 1);
        } else if (direction === "prev" && currentPage > 1) {
            setCurrentPage((prevPage) => prevPage - 1);
        }
    };

    // Paginate data
    const paginatedData = injuryHistory
        ? injuryHistory.slice(
              (currentPage - 1) * pageSize,
              currentPage * pageSize
          )
        : [];
    
    if (contentLoading) {
        return (
            <div id="preloader" className="flex items-center justify-center">
                <div className="flex loader-box justify-center items-center ">
                    <span className="text-center">
                        <img
                            className="mx-auto d-block"
                            alt="loader"
                            width="150"
                            src={contentLoader}
                        />
                    </span>
                </div>
            </div>
        );
    } else {
        return (
            <div className="modal-box players-modal p-0">
                <div className="players-modal-header flex justify-between items-center border-b p-5 pb-4">
                    <h3 className="font-bold text-lg">Injury History</h3>
                    <label
                        htmlFor="injuryHistory"
                        className="h-[25px] w-[25px] mt-[-4px] rounded-full flex cursor-pointer justify-center items-center bg-white shadow-lg text-sm border font-semibold"
                    >
                        <div className="ml-px">x</div>
                    </label>
                </div>
                <div className="p-5 pt-0">
                    <div className="hidden md:block">
                        <div>
                            <div className="player-profile font-semibold">
                                <table className="table w-full">
                                    <tbody>
                                        {paginatedData?.map((history, index) => (
                                            <tr
                                                key={index}
                                                className={`${
                                                    index !==
                                                    paginatedData.length - 1
                                                        ? "border-b-[1.5px] border-[#F2F3F6]"
                                                        : ""
                                                } text-base text-[#9299AA] font-medium`}
                                            >
                                                <td className="text-center">
                                                    {history?.season}
                                                </td>
                                                <td>
                                                    <div className="text-[#22272F] font-semibold">
                                                        {history?.injury}
                                                    </div>
                                                </td>
                                                <td className="text-center">
                                                    {history?.games_missed} Games
                                                </td>
                                                <td>
                                                    <div className="text-[#22272F] font-semibold">
                                                        {history?.days}
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    {/* Table for mobile */}
                    <div className="pt-4 md:hidden profile-table-mobile font-semibold">
                        <table className="table w-full">
                            <tbody>
                                {paginatedData?.map((history, index) => (
                                    <tr
                                        key={index}
                                        className={`${
                                            index !== paginatedData.length - 1
                                                ? "border-b-[1.5px] border-[#F2F3F6]"
                                                : ""
                                        } text-base text-[#9299AA] font-medium`}
                                    >
                                        <td className="text-center">
                                            {history?.season}
                                        </td>
                                        <td className="text-start">
                                            <div className="text-[#22272F] whitespace-normal leading-[16px] font-semibold">
                                                {history?.injury}
                                            </div>
                                        </td>
                                        <td className="text-right">
                                            <div className="text">
                                                {history?.games_missed} Games
                                            </div>
                                        </td>
                                        <td className="text-center">
                                            <div className="text-[#22272F] whitespace-normal leading-[16px] font-semibold">
                                                {history?.days}
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    {/* Pagination Controls */}
                    {totalPages > 1 && (
                        <div className="flex justify-between items-center pt-4">
                            <button
                                className="py-2 w-[74px] px-3 rounded-lg border text-xs"
                                onClick={() => handlePageChange("prev")}
                                disabled={currentPage === 1}
                            >
                                Previous
                            </button>
                            <span>
                                Page {currentPage} of {totalPages}
                            </span>
                            <button
                                className="py-2 w-[74px] px-3 rounded-lg border text-xs"
                                onClick={() => handlePageChange("next")}
                                disabled={currentPage === totalPages}
                            >
                                Next
                            </button>
                        </div>
                    )}
                </div>
            </div>
        );
    }
};

export default InjuryHistory;
