import React, { useContext, useEffect, useState } from "react";
import "./Dashboard.css";
import Separator from "../../Images//Separator.png";
import home from "../../Images/home.png";
import plane from "../../Images/plen.png";
import { formateDate } from "../../customFunctions/formateDate";
import UpcomingMatchesModal from "./Modal/UpcomingMatchesModal/UpcomingMatchesModal";
import CompletedMatchesModal from "./Modal/CompletedMatchesModal/CompletedMatchesModal";
import { sortBasedOnKey } from "../../customFunctions/sortingFunction";
import contentLoader from "../../Images/loader.gif";
import arrow from "../../Images/arrow.png";
import { DataContext } from "../../Context/DataProvider";
import { makePosition } from "../../customFunctions/makePosition";

const Dashboard = () => {
  const [upcomingMatchIndex, setUpcomingMatchIndex] = useState(0);
  const [completedMatchIndex, setCompletedMatchIndex] = useState(0);
  const [matches, setMatches] = useState([]);
  const [upcomingMatches, setUpcomingMatches] = useState([]);
  const [analysis, setAnalysis] = useState([]);
  const [points, setPoints] = useState(null);
  const [leagues, setLeagues] = useState(null);
  const [togglePointsSort, setTogglePointsSort] = useState(true);
  const [contentLoading, setContentLoading] = useState(true);
  const [dashTableSeasonDropdown, setDashTableSeasonDropdown] = useState(false);
  const [dashTableDayDropdown, setDashTableDayDropdown] = useState(false);
  const [serverError, setServerError] = useState(null);

  const { user, base_url, token } = useContext(DataContext);

  useEffect(() => {
    // setContentLoading(true);

    fetch(`${base_url}/api/v1/dashboard/leagues`, {
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setLeagues(data.sort((a, b) => b.points - a.points));
        setServerError(false);
      })
      .catch((error) => {
        if (error) {
          setServerError(true);
        }
      });
  }, []);

  useEffect(() => {
    // setContentLoading(true);
    fetch(`${base_url}/api/v1/dashboard/analysis`, {
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setAnalysis(data);
        // setContentLoading(false);
      });
  }, []);

  useEffect(() => {
    
    fetch(`${base_url}/api/v1/dashboard/points`, {
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setPoints(data);
      });
  }, []);

  useEffect(() => {
    // setContentLoading(true);
    fetch(`${base_url}/api/v1/dashboard`, {
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setMatches(data);
        setContentLoading(false);
      })
      .catch((error) => {
        if (error) {
          alert("server error happened");
        }
      });
  }, []);

  useEffect(() => {
    // setContentLoading(true);
    fetch(`${base_url}/api/v1/dashboard/upcomming_matches`, {
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setUpcomingMatches(data);
        setContentLoading(false);
      })
      .catch((error) => {
        if (error) {
          alert("server error happened");
        }
      });
  }, []);



  const filteredUpcomingMatches = upcomingMatches?.filter(
    (match) =>
      match.league_code === user?.league &&
      (match.home_team === user?.team ||
        match.away_team === user?.team)
  );

  const completedMatches = matches?.filter(
    (match) =>
      //   match.home_xG_SC !== "." ||
      //   match.home_xG_SC !== null &&
      //   match.away_xG_SC !== "." ||
      //   match.away_xG_SC !== null &&
      //   match.home_xG_BP !== "." ||
      //   match.home_xG_BP !== null &&
      //   match.away_xG_BP !== "." ||
      //   match.away_xG_BP !== null
      match.status === "completed"
  );

  // Logic for moving to the previous item
  const goToPreviousUpcomingMatch = () => {
    setUpcomingMatchIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };
  const goToPreviousCompletedMatch = () => {
    setCompletedMatchIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  // Logic for moving to the next item
  const goToNextUpcomingMatch = () => {
    setUpcomingMatchIndex((prevIndex) =>
      Math.min(prevIndex + 1, filteredUpcomingMatches.length - 1)
    );
  };
  const goToNextCompletedMatch = () => {
    setCompletedMatchIndex((prevIndex) =>
      Math.min(prevIndex + 1, completedMatches?.length - 1)
    );
  };

  // get analysis title via analysis points
  function getTitle(points) {
    points = typeof points === 'string' ? parseFloat(points) : points;
    if (points >= 0.07) {
        return { title: "Top Six", color: "bg-[#02b059]", filledDots: 5 };
    } else if (points <= 0.06 && points >= 0.03 ) {
        return { title: "Europa League", color: "bg-[#02b059]", filledDots: 4 };
    } else if (points <= 0.02 && points >= -0.01  ) {
        return { title: "Mid-Table", color: "bg-[#f29445]", filledDots: 3 };
    } else if (points <= -0.02 && points >= -0.07  ) {
        return { title: "Bottom 3rd", color: "bg-[#fa4265]", filledDots: 2 };
    } else if (points <= -0.08) {
        return { title: "Relegation", color: "bg-[#fa4265]", filledDots: 1 };
    }else{
        return { title: "no title", color: "bg-[#fa4265]", filledDots: 0 };
    }
  }



  function modifyTitle(title){
    if(title==="Goalkeeper"){
      return "Goalkeeper";
    }else if(title==="Centre-Backs"){
      return "Centre-Backs";
    }
    else if(title==="Full-Backs"){
      return "Full-Backs";
    }
    else if(title==="Midfielders"){
      return "Central Midfielders";
    }
    else if(title==="Offensive Forwards"){
      return "Attacking Midfielders";
    }
    else if(title==="Centre-Forwards"){
      return "Centre-Forwards";
    }
    else if(title==="Coach"){
      return "Coach";
    }
  }




  function findHighLowMiddle(value, ...values) {
    values.push(value); // Add the initial value to the array
    values.sort((a, b) => a - b); // Sort the values in ascending order

    if (value === values[0]) {
      return "low";
    } else if (value === values[values.length - 1]) {
      return "high";
    }
  }

  if (contentLoading) {
    return (
      <div id="preloader" className="flex items-center justify-center">
        <div className="flex loader-box justify-center items-center ">
          <span className="text-center">
            <img
              className="mx-auto d-block"
              alt="loader"
              width="150"
              src={contentLoader}
            />
          </span>
        </div>
      </div>
    );
  } else {
    return (
      <div className="pt-5 xl:pt-10 xl:min-h-[90vh] text-neutral">
        <div className="dashboard px-4 xl:px-10">
          {/* Row 1 */}
          <div className="dashboard_row_1  grid grid-cols-1 xl:grid-cols-[27%,27%,22%,21%] gap-[1%]">
            {/* upcomming matches */}
            <div className="bg-base-100 flex flex-col justify-between rounded-lg shadow-lg">
              {/* Card Header */}
              <div className="px-2 2xl:px-6 py-3 2xl:py-4 flex items-center text-neutral border-b">
                <h6 className="font-semibold text-sm 2xl:text-base">
                  Upcoming Matches
                </h6>
              </div>

              {/* Card Body */}
              {filteredUpcomingMatches?.length > 0 ? (
                <>
                  <div className="pt-4 text-center">
                    <div className="text-xs 2xl:text-sm">
                      <h6 className="font-semibold">
                        {filteredUpcomingMatches[upcomingMatchIndex]?.league}
                      </h6>
                      <p className="text-neutral-content font-medium">
                        {!filteredUpcomingMatches[upcomingMatchIndex]?.date
                          ? "--"
                          : formateDate(
                              filteredUpcomingMatches[upcomingMatchIndex]?.date
                            )}
                      </p>
                    </div>
                    <div className="flex justify-between ">
                      <div className="mt-4 basis-[49.5%]  flex justify-center">
                        <div>
                          <img
                            src={`${base_url}/api/v1/team/image/${filteredUpcomingMatches[upcomingMatchIndex]?.home_team}`}
                            alt=""
                            className="w-10 h-10 2xl:w-[48px] 2xl:h-[48px] mx-auto"
                          />
                          <h6 className="font-semibold hidden md:block text-xs 2xl:text-sm mt-1">
                            {
                              filteredUpcomingMatches[upcomingMatchIndex]
                                ?.home_team_short
                            }
                          </h6>
                          <h6 className="font-semibold block md:hidden text-xs 2xl:text-sm mt-1">
                            {
                              filteredUpcomingMatches[upcomingMatchIndex]
                                ?.home_team_short
                            }
                          </h6>
                          <p className="font-medium text-[#9299AA] text-xs 2xl:text-sm">
                            {!filteredUpcomingMatches[upcomingMatchIndex]
                              ?.home_cumulative_points
                              ? "0 Points"
                              : filteredUpcomingMatches[upcomingMatchIndex]
                                  ?.home_cumulative_points + " Points"}{" "}
                          </p>
                          
                        </div>
                      </div>
                      <div className="basis-[1%] pt-[30px]">
                        <div className="w-[1.5px]  h-[48px] bg-[#F2F3F6] "></div>
                      </div>
                      <div className="mt-4 basis-[49.5%] flex justify-center">
                        <div>
                          <img
                            src={`${base_url}/api/v1/team/image/${filteredUpcomingMatches[upcomingMatchIndex]?.away_team}`}
                            alt=""
                            className="w-10 h-10 2xl:w-[48px] 2xl:h-[48px] mx-auto"
                          />
                          <h6 className="font-semibold hidden md:block text-xs 2xl:text-sm mt-1">
                            {
                              filteredUpcomingMatches[upcomingMatchIndex]
                                ?.away_team_short
                            }
                          </h6>
                          <h6 className="font-semibold block md:hidden text-xs 2xl:text-sm mt-1">
                            {
                              filteredUpcomingMatches[upcomingMatchIndex]
                                ?.away_team_short
                            }
                          </h6>
                          <p className="font-medium text-[#9299AA] text-xs 2xl:text-sm">
                            {!filteredUpcomingMatches[upcomingMatchIndex]
                              ?.away_cumulative_points
                              ? "0 Points"
                              : filteredUpcomingMatches[upcomingMatchIndex]
                                  ?.away_cumulative_points + " Points"}{" "}
                          </p>
                        </div>
                      </div>
                    </div>

                    {/* Progress */}
                    <div className="py-4 px-5 2xl:px-8">
                      <div className="flex justify-between items-center text-neutral-content text-xs  2xl:text-sm font-medium">
                        <p>
                          {(
                            filteredUpcomingMatches[upcomingMatchIndex]?.prob1 *
                            100
                          ).toFixed(0)}
                          %
                        </p>
                        {/* <p>{(filteredUpcomingMatches[upcomingMatchIndex]?.probtie*100).toFixed(2)}%</p> */}
                        <p>
                          {(
                            filteredUpcomingMatches[upcomingMatchIndex]?.prob2 *
                            100
                          ).toFixed(0)}
                          %
                        </p>
                      </div>
                      <div className="flex gap-px mt-1">
                        <div
                          style={{
                            width: `${
                              Number(
                                filteredUpcomingMatches[upcomingMatchIndex]
                                  ?.prob1
                              ) * 100
                            }%`,
                          }}
                          className={`h-2 ${
                            findHighLowMiddle(
                              filteredUpcomingMatches[upcomingMatchIndex]
                                ?.prob1 * 100,
                              filteredUpcomingMatches[upcomingMatchIndex]
                                ?.prob1 * 100,

                              filteredUpcomingMatches[upcomingMatchIndex]
                                ?.prob2 * 100
                            ) === "high" && "bg-primary"
                          } ${
                            findHighLowMiddle(
                              filteredUpcomingMatches[upcomingMatchIndex]
                                ?.prob1 * 100,
                              filteredUpcomingMatches[upcomingMatchIndex]
                                ?.prob1 * 100,

                              filteredUpcomingMatches[upcomingMatchIndex]
                                ?.prob2 * 100
                            ) === "medium" && "bg-[#EAEBF0]"
                          } ${
                            findHighLowMiddle(
                              filteredUpcomingMatches[upcomingMatchIndex]
                                ?.prob1 * 100,
                              filteredUpcomingMatches[upcomingMatchIndex]
                                ?.prob1 * 100,

                              filteredUpcomingMatches[upcomingMatchIndex]
                                ?.prob2 * 100
                            ) === "low" && "bg-[#FA4265]"
                          } rounded-l`}
                        ></div>
                        <div
                          style={{
                            width: `${
                              Number(
                                filteredUpcomingMatches[upcomingMatchIndex]
                                  ?.prob_draw
                              ) * 100
                            }%`,
                          }}
                          className={`h-2 bg-[#EAEBF0]`}
                        ></div>
                        <div
                          style={{
                            width: `${
                              Number(
                                filteredUpcomingMatches[upcomingMatchIndex]
                                  ?.prob2
                              ) * 100
                            }%`,
                          }}
                          className={`h-2 ${
                            findHighLowMiddle(
                              filteredUpcomingMatches[upcomingMatchIndex]
                                ?.prob2 * 100,
                              filteredUpcomingMatches[upcomingMatchIndex]
                                ?.prob1 * 100,

                              filteredUpcomingMatches[upcomingMatchIndex]
                                ?.prob2 * 100
                            ) === "high" && "bg-primary"
                          } ${
                            findHighLowMiddle(
                              filteredUpcomingMatches[upcomingMatchIndex]
                                ?.prob2 * 100,
                              filteredUpcomingMatches[upcomingMatchIndex]
                                ?.prob1 * 100,

                              filteredUpcomingMatches[upcomingMatchIndex]
                                ?.prob2 * 100
                            ) === "medium" && "bg-[#EAEBF0]"
                          } ${
                            findHighLowMiddle(
                              filteredUpcomingMatches[upcomingMatchIndex]
                                ?.prob2 * 100,
                              filteredUpcomingMatches[upcomingMatchIndex]
                                ?.prob1 * 100,

                              filteredUpcomingMatches[upcomingMatchIndex]
                                ?.prob2 * 100
                            ) === "low" && "bg-[#FA4265]"
                          } rounded-r`}
                        ></div>
                      </div>
                    </div>
                  </div>

                  {/* Card footer */}

                  <div className="p-2 mt-6 px-3 2xl:px-7 py-3 xl:py-4 flex justify-between gap-5 xl:gap-[60px] text-xs 2xl:text-sm">
                    <button
                      disabled={upcomingMatchIndex === 0}
                      onClick={goToPreviousUpcomingMatch}
                      className="py-2  text-center px-3 rounded-lg border"
                    >
                      Previous
                    </button>
                    <label
                      className="pt-[9px] px-3 cursor-pointer  text-center rounded-lg bg-primary text-base-100"
                      htmlFor="my-modal"
                    >
                      Details
                    </label>
                    <button
                      disabled={
                        upcomingMatchIndex + 1 ===
                        filteredUpcomingMatches.length
                      }
                      onClick={goToNextUpcomingMatch}
                      className="py-2 w-[74px] px-3 rounded-lg border"
                    >
                      Next
                    </button>
                  </div>
                </>
              ) : (
                <div className="flex justify-center items-center h-full">
                  'No Upcoming Matches'
                </div>
              )}
            </div>

              {/* completed matches */}
            <div className="bg-base-100 flex flex-col justify-between rounded-lg shadow-lg">
              {/* Card Header */}
              <div className="px-2 2xl:px-6 py-3 2xl:py-4 flex items-center text-neutral border-b">
                <h6 className="font-semibold text-sm 2xl:text-base">
                  Completed Matches
                </h6>
              </div>

              {/* Card Body */}
              <div className="pt-4 text-center">
                <div className="flex justify-between px-3 2xl:px-6 items-center text-xs 2xl:text-sm">
                  <div>
                    <h6 className="font-semibold">
                      {completedMatches[completedMatchIndex]?.league}
                    </h6>
                    <p className="text-neutral-content text-left font-medium">
                      {formateDate(completedMatches[completedMatchIndex]?.date)}
                    </p>
                  </div>
                  <div className="flex justify-between items-center">
                    <span className="b-rate b-rate-1 flex justify-between items-center">
                      {isNaN(
                        Math.round(
                          completedMatches[completedMatchIndex]?.home_win_prob *
                            100
                        )
                      ) ? (
                        <div className="ml-2">--</div>
                      ) : (
                        Math.round(
                          completedMatches[completedMatchIndex]?.home_win_prob *
                            100
                        ) + "%"
                      )}
                    </span>
                    <span className="b-rate flex justify-between items-center">
                      {isNaN(
                        Math.round(
                          completedMatches[completedMatchIndex]?.tie_prob * 100
                        )
                      ) ? (
                        <div className="ml-2">--</div>
                      ) : (
                        Math.round(
                          completedMatches[completedMatchIndex]?.tie_prob * 100
                        ) + "%"
                      )}
                    </span>
                    <span className="b-rate flex justify-between items-center">
                      {isNaN(
                        Math.round(
                          completedMatches[completedMatchIndex]?.away_win_prob *
                            100
                        )
                      ) ? (
                        <div className="ml-2">--</div>
                      ) : (
                        Math.round(
                          completedMatches[completedMatchIndex]?.away_win_prob *
                            100
                        ) + "%"
                      )}
                    </span>
                  </div>
                </div>

                <div className="mt-4 flex">
                  <div className="w-1/2 flex gap-1">
                    <div className="w-full flex justify-center text-center">
                      <div>
                        <img
                          src={`${base_url}/api/v1/team/image/${completedMatches[completedMatchIndex]?.home_team}`}
                          alt=""
                          className="w-10 h-10 2xl:w-[48px] 2xl:h-[48px] mx-auto"
                        />
                        <h6 className="font-semibold hidden md:block text-xs 2xl:text-sm mt-1">
                          {
                            completedMatches[completedMatchIndex]
                              ?.home_team_short
                          }
                        </h6>
                        {/* for mobile */}
                        <h6 className="font-semibold block md:hidden text-xs 2xl:text-sm mt-1">
                          {
                            completedMatches[completedMatchIndex]
                              ?.home_team_short
                          }
                        </h6>
                      </div>
                    </div>

                    <div className="w-10 py-3 2xl:py-[9px]">
                      <div className="bg-[#F7F7F8] h-full flex justify-center items-center font-semibold text-xl xl:text-2xl rounded">
                        {!completedMatches[completedMatchIndex]?.home_goals
                          ? "0"
                          : completedMatches[completedMatchIndex]?.home_goals}
                      </div>
                    </div>
                  </div>

                  <div className="w-9">
                    <img src={Separator} alt="" className="w-1 mx-auto" />
                  </div>

                  <div className="w-1/2 flex gap-1">
                    <div className="w-10 py-3 2xl:py-[9px]">
                      <div className="bg-[#F7F7F8] h-full flex justify-center items-center font-semibold text-xl xl:text-2xl rounded">
                        {!completedMatches[completedMatchIndex]?.away_goals
                          ? "0"
                          : completedMatches[completedMatchIndex]?.away_goals}
                      </div>
                    </div>
                    <div className="w-full flex justify-center text-center">
                      <div>
                        <img
                          src={`${base_url}/api/v1/team/image/${completedMatches[completedMatchIndex]?.away_team}`}
                          alt=""
                          className="w-10 h-10 2xl:w-[48px] 2xl:h-[48px] mx-auto"
                        />
                        <h6 className="font-semibold hidden md:block text-xs 2xl:text-sm mt-1">
                          {
                            completedMatches[completedMatchIndex]
                              ?.away_team_short
                          }
                        </h6>
                        {/* for mobile */}
                        <h6 className="font-semibold block md:hidden text-xs 2xl:text-sm mt-1">
                          {
                            completedMatches[completedMatchIndex]
                              ?.away_team_short
                          }
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-4 px-2 2xl:px-6 grid grid-cols-2 gap-2">
                  <div className="text-xs 2xl:text-sm flex justify-between items-center bg-[#F7F7F8] px-4 py-2 rounded-lg">
                    <h6 className="text-neutral-content font-medium">
                      Shot-Based xG
                    </h6>
                    <h6 className="text-neutral font-semibold">
                      {isNaN(completedMatches[completedMatchIndex]?.home_xg)
                        ? "0"
                        : Number(
                            completedMatches[completedMatchIndex]?.home_xg
                          ).toFixed(1)}
                    </h6>
                  </div>
                  <div className="text-xs 2xl:text-sm flex justify-between items-center bg-[#F7F7F8] px-4 py-2 rounded-lg">
                    <h6 className="text-neutral-content font-medium">
                      Shot-Based xG
                    </h6>
                    <h6 className="text-neutral font-semibold">
                      {isNaN(completedMatches[completedMatchIndex]?.away_xg)
                        ? "0"
                        : Number(
                            completedMatches[completedMatchIndex]?.away_xg
                          ).toFixed(1)}
                    </h6>
                  </div>
                  <div className="text-xs 2xl:text-sm flex justify-between items-center bg-[#F7F7F8] px-4 py-2 rounded-lg">
                    <h6 className="text-neutral-content font-medium">
                      Non-Shot xG
                    </h6>
                    <h6 className="text-neutral font-semibold">
                      {isNaN(completedMatches[completedMatchIndex]?.home_xG_BP)
                        ? "0"
                        : Number(
                            completedMatches[completedMatchIndex]?.home_xG_BP
                          ).toFixed(1)}
                    </h6>
                  </div>
                  <div className="text-xs 2xl:text-sm flex justify-between items-center bg-[#F7F7F8] px-4 py-2 rounded-lg">
                    <h6 className="text-neutral-content font-medium">
                      Non-Shot xG
                    </h6>
                    <h6 className="text-neutral font-semibold">
                      {isNaN(completedMatches[completedMatchIndex]?.away_xG_BP)
                        ? "0"
                        : Number(
                            completedMatches[completedMatchIndex]?.away_xG_BP
                          ).toFixed(1)}
                    </h6>
                  </div>
                </div>
              </div>

              {/* Card footer */}
              <div className="p-2 px-3 2xl:px-7 py-3 xl:py-4 flex justify-between gap-5 xl:gap-[60px] text-xs 2xl:text-sm">
                <button
                  disabled={completedMatchIndex === 0}
                  onClick={goToPreviousCompletedMatch}
                  className="py-2 px-3 rounded-lg border"
                >
                  Previous
                </button>
                <label
                  className="pt-[9px] px-3 cursor-pointer text-center rounded-lg bg-primary text-base-100"
                  htmlFor="bundeshliga-modal"
                >
                  Details
                </label>
                <button
                  disabled={completedMatchIndex + 1 === completedMatches.length}
                  onClick={goToNextCompletedMatch}
                  className="py-2 px-3 w-[74px] rounded-lg border"
                >
                  Next
                </button>
              </div>
            </div>

            <div className="bg-base-100 hidden rounded-lg shadow-lg">
              {/* Card Header */}
              <div className="px-2 2xl:px-6 py-3 2xl:py-4 flex justify-between items-center text-neutral border-b">
                <h6 className="font-semibold text-sm 2xl:text-base">
                  Analysis
                </h6>
                <div className="text-blue-600 text-sm font-semibold ">
                  Details
                </div>
              </div>

              {/* Card Body */}
              <div className="p-2 dashboard-analysis 2xl:p-4 text-xs xl:text-sm">
                {analysis.map((data, index) => (
                  <div
                    key={index}
                    className="px-2 2xl:px-4 py-2.5 rounded-lg flex justify-between items-center mb-[3px]"
                  >
                    <div className="" >
                      <h6 className="font-medium text-xs 2xl:text-sm ">{ modifyTitle(data?.title) }</h6>
                    </div>
                    <div className="flex justify-end gap-2 2xl:gap-3 ">
                      <div className="text-[#7C86A1] text-xs 2xl:text-sm font-medium ">
                        {getTitle(data?.points)?.title} 
                      </div>
                      <div className="flex items-center gap-[2px] ">
                        {[...Array(getTitle(data?.points)?.filledDots)].map(
                          (_, index) => (
                            <div
                              key={index}
                              className={`rounded-full h-[6px] w-[6px] ${
                                getTitle(data?.points)?.color
                              } `}
                            ></div>
                          )
                        )}
                        {[...Array(5 - getTitle(data?.points)?.filledDots)].map(
                          (_, index) => (
                            <div key={index} className="rounded-full h-[6px] w-[6px] bg-[#9299AA7A] "></div>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="bg-base-100 hidden md:block mb-10 xl:mb-0 rounded-lg shadow-lg">
              {/* Card Header */}
              <div className="px-2 2xl:px-6 py-3 2xl:py-4 flex items-center text-neutral border-b">
                <h6 className="font-semibold text-sm 2xl:text-base">Points</h6>
              </div>

              {/*  card body */}
              <div className="p-2 2xl:p-4">
                {/* Chart */}
                <div className="grid grid-cols-2">
                  <div className="px-2 2xl:px-4">
                    <img src={home} alt="" className="w-10 h-10 mx-auto" />
                    <div className="mt-4 grid grid-cols-3 gap-2 h-28 2xl:h-[122px]">
                      <div className="px-2 flex flex-col items-center">
                        <div className="bg-gray-100 rounded w-1.5 h-20 2xl:h-[100px] mx-auto relative">
                          <div
                            style={{
                              height: `${Math.round(points?.total_home_predicted_points_percentile)}%`,
                            }}
                            className={`w-1.5 bg-orange-400 absolute bottom-0 rounded`}
                          ></div>
                        </div>
                        <p className="text-xs font-semibold mt-1.5">
                          {Math.round(points?.total_home_predicted_points)}
                        </p>
                      </div>

                      <div className="px-2 flex flex-col items-center">
                        <div className="bg-gray-100 rounded w-1.5 h-20 2xl:h-[100px] mx-auto relative">
                          <div
                            style={{
                              height: `${Math.round(points?.total_home_points_percentile)}%`,
                            }}
                            className="w-1.5 bg-secondary absolute bottom-0 rounded"
                          ></div>
                        </div>
                        <p className="text-xs font-semibold mt-1.5">
                          {Math.round(points?.total_home_points)}
                        </p>
                      </div>

                      <div className="px-2 flex flex-col items-center">
                        <div className="bg-gray-100 rounded w-1.5 h-20 2xl:h-[100px] mx-auto relative">
                          <div
                            style={{
                              height: `${Math.round(points?.total_home_deserved_points_percentile)}%`,
                            }}
                            className="w-1.5 bg-primary absolute bottom-0 rounded"
                          ></div>
                        </div>
                        <p className="text-xs font-semibold mt-1.5">
                          {Math.round(points?.total_home_deserved_points)}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="px-2 2xl:px-4">
                    <img src={plane} alt="" className="w-10 h-10 mx-auto" />
                    <div className="mt-4 grid grid-cols-3 gap-2 h-28 2xl:h-[122px]">
                      <div className="px-2 flex flex-col items-center">
                        <div className="bg-gray-100 rounded w-1.5 h-20 2xl:h-[100px] mx-auto relative">
                          <div
                            style={{
                              height: `${Math.round(points?.total_away_predicted_points_percentile)}%`,
                            }}
                            className="w-1.5 bg-orange-400 absolute bottom-0 rounded"
                          ></div>
                        </div>
                        <p className="text-xs font-semibold mt-1.5">
                          {Math.round(points?.total_away_predicted_points)}
                        </p>
                      </div>

                      <div className="px-2 flex flex-col items-center">
                        <div className="bg-gray-100 rounded w-1.5 h-20 2xl:h-[100px] mx-auto relative">
                          <div
                            style={{
                              height: `${Math.round(points?.total_away_points_percentile)}%`,
                            }}
                            className="w-1.5 bg-secondary absolute bottom-0 rounded"
                          ></div>
                        </div>
                        <p className="text-xs font-semibold mt-1.5">
                          {Math.round(points?.total_away_points)}
                        </p>
                      </div>

                      <div className="px-2 flex flex-col items-center">
                        <div className="bg-gray-100 rounded w-1.5 h-20 2xl:h-[100px] mx-auto relative">
                          <div
                            style={{
                              height: `${Math.round(points?.total_away_deserved_points_percentile)}%`,
                            }}
                            className="w-1.5 bg-primary absolute bottom-0 rounded"
                          ></div>
                        </div>
                        <p className="text-xs font-semibold mt-1.5">
                          {Math.round(points?.total_away_deserved_points)}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-[18px] bg-[#F7F7F8] px-4 py-2 rounded-lg flex justify-between items-center mb-[3px]">
                  <h6 className="font-medium text-sm text-neutral-content">
                    Total points
                  </h6>
                  <p className="text-sm font-semibold ">
                    {points?.total_points}
                  </p>
                </div>
              </div>

              {/* Footer */}
              <div className="px-2 2xl:px-6 py-2 2xl:py-4 flex justify-between text-xs 2xl:text-sm font-medium text-neutral-content">
                <div className="flex items-center gap-1">
                  <div className="w-2 h-2 rounded-full bg-[#F29445]"></div>
                  <p>Predicted</p>
                </div>
                <div className="flex items-center gap-1">
                  <div className="w-2 h-2 rounded-full bg-secondary"></div>
                  <p>Achieved</p>
                </div>
                <div className="flex items-center gap-1">
                  <div className="w-2 h-2 rounded-full bg-primary"></div>
                  <p>Deserved</p>
                </div>
              </div>
            </div>
          </div>

          {/* Row 2 / Table */}
          <div className=" dashboard-table  mt-4 md:mt-10 bg-base-100 rounded-lg shadow-lg">
            {/* Table Header */}
            <div className="px-4 xl:px-6 py-4 flex items-center justify-between text-neutral border-b">
              <div>
                <h6 className="font-semibold text-xs 2xl:text-base">
                  League Table
                </h6>
              </div>

              <div className="flex gap-2 hidden items-center w-1/8">
                <div className="relative">
                  <button
                    onClick={() =>
                      setDashTableDayDropdown(!dashTableDayDropdown)
                    }
                    className="w-[106px] input-border py-1 xl-py-2 px-2 xl:px-3 rounded-lg outline-none flex justify-between items-center bg-base-100"
                  >
                    <p className="text-xs 2xl:text-sm">Today</p>
                    <img src={arrow} alt="" />
                  </button>

                  {dashTableDayDropdown && (
                    <div className="absolute w-[100px] mt-2 p-2 right-0 bg-base-100 shadow-xl rounded-lg  z-50 text-neutral-content">
                      <p className="cursor-pointer p-1.5 hover:bg-[#F7F7F8] hover:text-neutral duration-300">
                        Today
                      </p>
                      <p className="cursor-pointer p-1.5 hover:bg-[#F7F7F8] hover:text-neutral duration-300">
                        Today
                      </p>
                      <p className="cursor-pointer p-1.5 hover:bg-[#F7F7F8] hover:text-neutral duration-300">
                        Today
                      </p>
                      <p className="cursor-pointer p-1.5 hover:bg-[#F7F7F8] hover:text-neutral duration-300">
                        Today
                      </p>
                    </div>
                  )}
                </div>
                <div className="relative">
                  <button
                    onClick={() =>
                      setDashTableSeasonDropdown(!dashTableSeasonDropdown)
                    }
                    className="w-[106px] input-border py-1 xl-py-2 px-2 xl:px-3 rounded-lg outline-none flex justify-between items-center bg-base-100"
                  >
                    <p className="text-xs 2xl:text-sm">2022-23</p>
                    <img src={arrow} alt="" />
                  </button>

                  {dashTableSeasonDropdown && (
                    <div className="absolute w-[100px] mt-3 p-2 right-0 bg-base-100 shadow-xl rounded-lg  z-50 text-neutral-content">
                      <p className="cursor-pointer p-1.5 hover:bg-[#F7F7F8] hover:text-neutral duration-300">
                        2022-23
                      </p>
                      <p className="cursor-pointer p-1.5 hover:bg-[#F7F7F8] hover:text-neutral duration-300">
                        2022-23
                      </p>
                      <p className="cursor-pointer p-1.5 hover:bg-[#F7F7F8] hover:text-neutral duration-300">
                        2022-23
                      </p>
                      <p className="cursor-pointer p-1.5 hover:bg-[#F7F7F8] hover:text-neutral duration-300">
                        2022-23
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="overflow-x-auto hidden md:block py-4 mb-[40px] font-semibold ">
              <table className="table w-full">
                <thead className="text-neutral-content">
                  <tr>
                    <th className="xl:w-[300px]">
                      <div className="flex items-center text-center pl-6">
                        <p className="xl:w-[80px] ml-[-10px] text-start">PTS</p>
                        <p className="xl:w-[220px] text-start">TEAM</p>
                      </div>
                    </th>

                    <th className="w-[153px]">
                      <div className=" grid grid-cols-2 gap-1 items-center text-center">
                        <h6 className=" ml-10  min-[1750px]:ml-[45px] min-[1800px]:ml-[47px] min-[1920px]:ml-[46px]">
                          OFF
                        </h6>
                        <h6 className="ml-9 min-[1440px]:ml-1 min-[1750px]:ml-[37px] min-[1800px]:ml-[30px] min-[1920px]:ml-5">
                          DEF
                        </h6>
                      </div>
                    </th>

                    <th className="w-[149px]">
                      <div className="ml-[40px] min-[1500px]:ml-[20px] min-[1750px]:ml-8 grid grid-cols-2 gap-5 min-[1500px]:gap-0 items-center text-center">
                        <h6 className="min-[1500px]:ml-[1px]">GD</h6>
                        <h6
                          onClick={() => {
                            sortBasedOnKey(
                              "points",
                              togglePointsSort,
                              leagues,
                              setLeagues
                            );
                            setTogglePointsSort(!togglePointsSort);
                          }}
                          className="ml-1 min-[1650px]:ml-[-10px] cursor-pointer min-[1700px]:ml-[-30px] min-[1750px]:ml-[20px] min-[1900px]:ml-[2px] "
                        >
                          POINTS
                        </h6>
                      </div>
                    </th>

                    <th className="w-[300px] min-[1400px]:w-[200px] min-[1750px]:w-[220px]">
                      <p className="ml-4 min-[1750px]:ml-8 text-center">FORM</p>
                    </th>

                    <th className="w-[100px] 2xl:w-[132px]">
                      <p className="ml-[-10px] min-[1550px]:ml-0 min-[1750px]:ml-5 ">
                        RELEGATION
                      </p>
                    </th>

                    <th className="w-[100px] 2xl:w-[132px]">
                      <p className="text-center ml-[-7px] pr-5 2xl:pr-4">
                        UCL QUALI
                      </p>
                    </th>

                    <th className="w-[100px] 2xl:w-[135px]">
                      <p className="text-center pr-5 ml-[-13px] 2xl:ml-[-20px] 2xl:pr-[35px]">
                        LEAGUE WIN
                      </p>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {leagues?.map((data, index) => (
                    <tr key={index}>
                      <td>
                        <div className="flex gap-2 items-center">
                          <p className="w-8 h-8 flex justify-center items-center bg-gray-100 rounded">
                            {data?.pts}
                          </p>

                          <p className="w-8 h-8 flex justify-center items-center">
                            {index + 1}.
                          </p>

                          <div className="flex gap-2 items-center">
                            <img
                              src={`${base_url}/api/v1/team/image/${data?.team}`}
                              alt=""
                              className="w-6 h-6 rounded-full"
                            />
                            <p className="font-medium text-sm xl:text-base">
                              {data?.team}
                            </p>
                          </div>
                        </div>
                      </td>

                      <td>
                        <div className="flex items-center">
                          <div className="w-11 2xl:w-[49px] flex justify-center items-center">
                            <div className="w-px h-6 border-r"></div>
                          </div>
                          <div className="flex gap-2 font-semibold">
                            <div
                              className={`w-[48px] border h-8 ${
                                data?.off_rank > 33 && data?.off_rank < 67 && ""
                              }  ${
                                data?.off_rank > 66 && ""
                              } rounded flex justify-center items-center  ${
                                data?.off_rank < 34 && ""
                              }`}
                            >
                              {makePosition(data?.off_rank)
                                ? makePosition(data?.off_rank)
                                : "-"}
                            </div>
                            <div
                              className={`w-[48px] border h-8 ${
                                data?.def_rank > 33 && data?.def_rank < 67 && ""
                              }  ${
                                data?.def_rank > 66 && ""
                              } rounded flex justify-center items-center  ${
                                data?.def_rank < 34 && ""
                              }`}
                            >
                              {makePosition(data?.def_rank)
                                ? makePosition(data?.def_rank)
                                : "-"}
                            </div>
                          </div>
                        </div>
                      </td>

                      <td>
                        <div className="flex items-center ">
                          <div className="w-11 2xl:w-[49px] flex justify-center items-center">
                            <div className="w-px h-6 border-r"></div>
                          </div>
                          <div className="flex gap-2 text-neutral-content font-semibold">
                            <div className="w-[56px] h-8 bg-gray-100 rounded flex justify-center items-center">
                              {data?.goalDiff > 0 ? "+" : ""}
                              {Math.round(data?.goalDiff)}
                            </div>
                            <div className="w-[56px] h-8 bg-gray-100 rounded flex justify-center items-center">
                              {Math.round(data?.points)}
                            </div>
                          </div>
                        </div>
                      </td>

                      <td>
                        <div className="flex">
                          <div className="w-11 2xl:w-[49px] flex justify-center items-center">
                            <div className="w-px h-6 border-r"></div>
                          </div>
                          <div className="grid grid-cols-5 gap-[4px] font-semibold text-xs 2xl:text-base">
                            {data?.result?.split("").map((value, index) => (
                              <div
                                key={index}
                                className={`w-7 h-8 xl:w-8 xl:h-8 ${
                                  value === "W"
                                    ? "bg-secondary/10 text-secondary"
                                    : value === "L"
                                    ? "bg-red-100 text-red-700"
                                    : "bg-orange-100 text-orange-700"
                                } rounded flex justify-center items-center`}
                              >
                                {value}
                              </div>
                            ))}
                          </div>
                        </div>
                      </td>

                      <td>
                        <div className="flex min-[1750px]:ml-6 ">
                          <div className="grid grid-cols-1 gap-1">
                            <p className="text-center font-medium">
                              {data?.relegation}%
                            </p>
                            <progress
                              className="progress progress-primary w-[80px] 2xl:w-[100px] h-1"
                              value={data?.relegation}
                              max="100"
                            ></progress>
                          </div>
                          <div className="w-5 2xl:w-8"></div>
                        </div>
                      </td>

                      <td>
                        <div className="flex min-[1920px]:ml-4 ">
                          <div className="grid grid-cols-1 gap-1">
                            <p className="text-center font-medium">
                              {data?.top_4}%
                            </p>
                            <progress
                              className="progress progress-primary w-[80px] 2xl:w-[100px] h-1"
                              value={data?.top_4}
                              max="100"
                            ></progress>
                          </div>
                          <div className="w-5 2xl:w-8"></div>
                        </div>
                      </td>

                      <td>
                        <div className="grid grid-cols-1 gap-1">
                          <p className="ml-[20%] font-medium">
                            {data?.champion}%
                          </p>
                          <progress
                            className="progress progress-primary w-[80px] 2xl:w-[100px] h-1"
                            value={data?.champion}
                            max="100"
                          ></progress>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {/* table for mobile */}
            <div className="py-4 mb-[40px] block md:hidden font-semibold ">
              <table className=" w-full">
                {/* head */}

                <thead className="text-neutral-content text-xs">
                  <tr className="">
                    <th className="text-center pl-2 ">P</th>
                    <th className=" text-left pl-2 ">TEAM</th>
                    <th className="text-center">OFF</th>
                    <th className="text-center">DEF</th>
                    <th className="text-center">GD</th>
                    <th
                      onClick={() => {
                        sortBasedOnKey(
                          "points",
                          togglePointsSort,
                          leagues,
                          setLeagues
                        );
                        setTogglePointsSort(!togglePointsSort);
                      }}
                      className="text-center cursor-pointer pr-2"
                    >
                      PTS
                    </th>
                  </tr>
                </thead>

                <tbody className="text-xs">
                  {leagues?.map((data, index) => (
                    <tr key={index}>
                      <td className="">
                        <p className="w-[18px] h-[18px] ml-2 flex justify-center items-center bg-gray-100 rounded">
                          {/* {data?.pts ? data.pts : "-"} */}
                          {data?.pts}
                        </p>
                      </td>
                      <td>
                        <div className="flex gap-1 items-center">
                          <p className="w-[18px] h-[18px] flex justify-center items-center">
                            {index + 1}.
                          </p>

                          <div className="flex gap-1 items-center">
                            <img
                              src={`${base_url}/api/v1/team/image/${data?.team}`}
                              alt=""
                              className="w-4 h-4 rounded-full"
                            />
                            <p className="font-semibold pr-2 whitespace-wrap text-xs ">
                              <span className="block pr-1">
                                {data?.team_short}
                              </span>
                            </p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div
                          className={`w-[33px] h-[22px] ${
                            data?.off_rank > 33 && data?.off_rank < 67 && ""
                          }  ${
                            data?.off_rank > 66 && ""
                          } rounded flex justify-center items-center  ${
                            data?.off_rank < 34 && ""
                          } rounded border flex justify-center items-center`}
                        >
                          {makePosition(data?.off_rank)
                            ? makePosition(data?.off_rank)
                            : "-"}
                        </div>
                      </td>
                      <td>
                        <div
                          className={`w-[33px] h-[22px] ${
                            data?.def_rank > 33 && data?.def_rank < 67 && ""
                          }  ${
                            data?.def_rank > 66 && ""
                          } rounded flex justify-center items-center  ${
                            data?.def_rank < 34 && ""
                          } border rounded flex justify-center items-center`}
                        >
                          {makePosition(data?.def_rank)
                            ? makePosition(data?.def_rank)
                            : "-"}
                        </div>
                      </td>
                      <td>
                        <div className=" h-[22px] w-[39px] bg-gray-100 rounded flex justify-center items-center">
                          {data?.goalDiff > 0 ? "+" : ""}
                          {Math.round(data?.goalDiff)}
                        </div>
                      </td>
                      <td className="">
                        <div className=" mr-2 h-[22px] w-[35px] bg-gray-100 rounded flex justify-center items-center">
                          {Math.round(data?.points)}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <UpcomingMatchesModal upcomingMatches={upcomingMatches} />
        <CompletedMatchesModal completedMatches={completedMatches} />
      </div>
    );
  }
};

export default Dashboard;
